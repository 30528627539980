<template>
  <div class="productspec">
    <div class="productspec-title">
      <p class="productspec-title-1">{{ $t('productSpec.msg_1') }}</p>
      <p class="productspec-title-2" @click="jump('product')">BR-W950</p>
    </div>
    <div class="productspec-img">
      <img class="productspec-img-1" src="../../assets/images/words@2x.png" alt="">
    </div>
    <!-- 详情-->
    <section class="productspec-detail">
      <div class="productspec-detail-title">
        <p><span class="productspec-detail-title-icon">/</span>{{ $t('productSpec.msg_2') }}</p>
      </div>
      <div class="productspec-detail-desc" v-for="(item, index) in descList" :key="index">
        <div class="desc-title">
          <p><span class="desc-title-icon">//</span>{{ $store.state.lang === 'zh' ? item.title : item.title_en }}</p>
        </div>
        <!-- 具体详情 -->
        <div :class="['desc-detail', descIndex % 2 === 0 ? 'desc-detail-bg-1' : '']" v-for="(descItem, descIndex) in item.descChildren" :key="descIndex">
          <div class="desc-detail-label">{{ $store.state.lang === 'zh' ? descItem.label : descItem.label_en }}</div>
          <div class="desc-detail-value">{{ $store.state.lang === 'zh' ? descItem.value : descItem.value_en }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      descList: [
        {
          title: '功能',
          title_en: 'Features',
          descChildren: [
            {
              label: '会车礼让',
              value: '√',
              label_en: 'Comity',
              value_en: '√'
            },
            {
              label: '跨越减速带',
              value: '√',
              label_en: 'Crossing the speed bump',
              value_en: '√'
            },
            {
              label: '光噪滤除',
              value: '√',
              label_en: 'Light noise filter',
              value_en: '√'
            },
            {
              label: '全景视野',
              value: '√',
              label_en: 'Panoramic view',
              value_en: '√'
            },
            {
              label: '跨越斜坡',
              value: '即将开放',
              label_en: 'Across the slope',
              value_en: 'Opening soon'
            },
            {
              label: '自动前往补给点',
              value: '√',
              label_en: 'Go to the supply point automatically',
              value_en: '√'
            },
            {
              label: '自动补水和排污',
              value: '√',
              label_en: 'Automatic water replenishment and drainage',
              value_en: '√'
            },
            {
              label: '自动充电',
              value: '√',
              label_en: 'Automatic charging',
              value_en: '√'
            },
            {
              label: '清洁效率超群',
              value: '√',
              label_en: 'Excellent cleaning efficiency',
              value_en: '√'
            },
            {
              label: '地图部署图形化',
              value: '√',
              label_en: 'Graphical map deployment',
              value_en: '√'
            },
            {
              label: '清洁业务数字化',
              value: '√',
              label_en: 'Digitalization of the cleaning business',
              value_en: '√'
            },
            {
              label: '远程通知',
              value: '√',
              label_en: 'Remote notification',
              value_en: '√'
            },
            {
              label: '远程数据查看',
              value: '√',
              label_en: 'Remote data viewing',
              value_en: '√'
            },
            {
              label: '自锁安全模式',
              value: '√',
              label_en: 'Self-locking safety mode',
              value_en: '√'
            },
            {
              label: '方向盘内置触控屏',
              value: '√',
              label_en: 'Steering wheel built-in touch screen',
              value_en: '√'
            },
          ],
        },
        {
          title: 'performance',
          title_en: '',
          descChildren: [
            {
              label: '实际清洁效率（不含导航、会车时间）',
              value: '2200~2300㎡/h',
              label_en: 'Actual cleaning efficiency (excluding navigation and meeting time)',
              value_en: '2200~2300㎡/h'
            },
            {
              label: '任务效率（包含导航、会车时间）',
              value: '1700㎡/h（会车频率7cph）；1850㎡/h（会车频率2cph）',
              label_en: 'Task efficiency (including navigation and meeting time)',
              value_en: '1700㎡/h (meeting frequency 7cph); 1850㎡/h (meeting frequency 2cph)'
            },
            {
              label: '覆盖率',
              value: '90~93%',
              label_en: 'Coverage',
              value_en: '90~93%'
            },
            {
              label: '最大日工作时长',
              value: '11h40m',
              label_en: 'Maximum daily working hours',
              value_en: '11h40m'
            },
            {
              label: '最大日清洁面积',
              value: '17500㎡',
              label_en: 'Maximum daily cleaning area',
              value_en: '17500㎡'
            },
            {
              label: '充电时长',
              value: '7h',
              label_en: 'Charging time',
              value_en: '7h'
            },

          ],
        },
        {
          title: '规格',
          title_en: 'specification',
          descChildren: [
            {
              label: '尺寸',
              value: '1900×1100×1693mm',
              label_en: 'size',
              value_en: '1900×1100×1693mm'
            },
            {
              label: '清水箱容积',
              value: '135L',
              label_en: 'sizClean water tank volumee',
              value_en: '135L'
            },
            {
              label: '污水箱容积',
              value: '180L',
              label_en: 'Wastewater tank volume',
              value_en: '180L'
            },
            {
              label: '作业宽度',
              value: '950mm',
              label_en: 'Working width',
              value_en: '950mm'
            },
            {
              label: '主刷',
              value: '×2',
              label_en: 'Main brush',
              value_en: '×2'
            },
            {
              label: '3D激光雷达',
              value: '×1',
              label_en: '3D Lidar',
              value_en: '×1'
            },
            {
              label: '深度相机',
              value: '×5',
              label_en: 'Depth camera',
              value_en: '×5'
            },
            {
              label: '全景摄像头',
              value: '×1组',
              label_en: 'Panoramic camera',
              value_en: '×1 group'
            },
            {
              label: '自动补给背包',
              value: '×1',
              label_en: 'Automatic replenishment backpack',
              value_en: '×1'
            },
          ],
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    jump (path) {
      this.$router.push({
        path: '/' + path
      })
    }
  },
}
</script>

<style lang="less" scope>
// 大屏样式
@media (min-width: 1001px) {
  .productspec {
    .productspec-title {
      font-size: .22rem;
      color: #C0C2C4;
      height: .6rem;
      line-height: .6rem;
      background-color: #31363c;
      width: 100%;
      .productspec-title-1 {
        display: inline-block;
        float: right;
        margin-right: 1.5rem;
        color: #006DA0;
        cursor: pointer;
      }
      .productspec-title-2 {
        display: inline-block;
        float: right;
        margin-right: .6rem;
        cursor: pointer;
      }
      .productspec-title-2:hover {
        color: #006DA0;
      }
    }

    // 图片
    .productspec-img {
      height: 1.22rem;
      position: relative;

      .productspec-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: -.28rem;
        left: 1.5rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // 详情
    .productspec-detail {
      width: 12.21rem;
      margin: 0 auto 1.64rem auto;

      .productspec-detail-title {
        color: #F0F2F4;
        font-size: .38rem;
        font-family: 'SourceHanSansCNBold';

        .productspec-detail-title-icon {
          padding-right: .2rem;
        }
      }

      .productspec-detail-desc {
        .desc-title {
          font-size: .28rem;
          color: #C0C2C4;
          margin-top: .45rem;
          margin-bottom: .45rem;

          .desc-title-icon {
            padding-right: .15rem;
          }
        }

        .desc-detail-bg-1 {
          background-color: #333841;
        }


        .desc-detail {
          line-height: .52rem;
          height: .52rem;
          font-size: .16rem;
          color: #C0C2C4;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .desc-detail-label {
            padding-left: .4rem;
            width: 4.14rem;
            display: inline-block;
          }

          .desc-detail-value {
            display: inline-block;
            flex: 1;
          }
        }
      }
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .productspec {
    .productspec-title {
      font-size: .22rem;
      color: #C0C2C4;
      height: .8rem;
      line-height: .8rem;
      background-color: #31363c;
      width: 100%;
      .productspec-title-1 {
        display: inline-block;
        float: right;
        margin-right: .4rem;
        color: #006DA0;
      }
      .productspec-title-2 {
        display: inline-block;
        float: left;
        margin-left: .4rem;
      }
    }

    // 图片
    .productspec-img {
      height: 1.7rem;
      position: relative;

      .productspec-img-1 {
        width: .98rem;
        height: 1.16rem;
        position: absolute;
        top: .13rem;
        left: .4rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    // 详情
    .productspec-detail {
      width: 6.7rem;
      margin: 0 auto .93rem auto;

      .productspec-detail-title {
        color: #F0F2F4;
        font-size: .42rem;
        font-family: 'SourceHanSansCNBold';

        .productspec-detail-title-icon {
          padding-right: .2rem;
        }
      }

      .productspec-detail-desc {
        .desc-title {
          font-size: .28rem;
          color: #C0C2C4;
          margin-top: .45rem;
          margin-bottom: .45rem;

          .desc-title-icon {
            padding-right: .15rem;
          }
        }

        .desc-detail-bg-1 {
          background-color: #333841;
        }


        .desc-detail {
          line-height: .52rem;
          font-size: .22rem;
          color: #C0C2C4;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;

          .desc-detail-label {
            padding-left: .4rem;
            width: 4.14rem;
            display: inline-block;
          }

          .desc-detail-value {
            padding-right: .21rem;
            display: inline-block;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>